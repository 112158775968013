@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Lendis';
  src: url('./assets/fonts/Aeonik-Regular.woff2') format('woff2'),
    url('./assets/fonts/Aeonik-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lendis';
  src: url('./assets/fonts/Aeonik-RegularItalic.woff2') format('woff2'),
    url('./assets/fonts/Aeonik-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Lendis';
  src: url('./assets/fonts/Aeonik-Medium.woff2') format('woff2'),
    url('./assets/fonts/Aeonik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lendis';
  src: url('./assets/fonts/Aeonik-Bold.woff2') format('woff2'),
    url('./assets/fonts/Aeonik-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Lendis';
  src: url('./assets/fonts/Aeonik-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/Aeonik-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Lendis';
  src: url('./assets/fonts/Aeonik-Light.woff2') format('woff2'),
    url('./assets/fonts/Aeonik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Lendis';
  src: url('./assets/fonts/Aeonik-LightItalic.woff2') format('woff2'),
    url('./assets/fonts/Aeonik-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

body {
  margin: 0;
  font-family: Lendis, 'ui-sans-serif', 'system-ui', '-apple-system', BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}
